import { ChevronRightIcon, PauseIcon } from "@heroicons/react/20/solid";
import { from_float_seconds_to_mm_ss } from "../../../utils/time_displayer";
import { get_this_server_url } from "../../../utils/local_server";
import { _play_audio } from "../../../utils/audio_player";
import { useState } from "react";
import SearchContentList from "../../../shared_components/Widgets/search_content_list/SearchContentList";
import i18next from "../../../i18next";
import SearchDirectContentList from "../../../shared_components/Widgets/search_content_list/SearchDirectContentList";
import SearchContentDossierList from "../../../shared_components/Widgets/search_content_list/SearchContentDossierList";

const { t } = i18next;

export default function DossierContentList(props) {
    const { content } = props;
    console.log(content.data)
    const mediaList = content.data.all_media;

    const [is_playing_song, set_is_playing_song] = useState(false);
    const [is_playing_song_id, set_is_playing_song_id] = useState(null);

    const handle_click_audio = ({media}) => {
        _play_audio(media, set_is_playing_song);
        set_is_playing_song_id(media.idMedia);
    }


    return <div className="px-4 py-2 border-l border-troto-3 mx-2 relative">
        <div className="lg:fixed lg:w-full bg-troto-1">
            <div className="text-troto-6 text-xl text-center lg:text-left lg:text-3xl ">
                {t("contents_and_suggestions")}
            </div>
            <hr className="relative left-[-20px] my-4 border-troto-3" />
        </div>

        <div className="mt-16 lg:overflow-scroll lg:h-screen lg:pb-32 lg:mt-20">
            <SearchContentDossierList data={mediaList} />
        </div>
    </div>;
}
