import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

const img_url = 'https://images.pexels.com/photos/5381983/pexels-photo-5381983.jpeg?auto=compress&cs=tinysrgb&w=600';
const element_height = "h-32";
const common_properties = "rounded-2xl overflow-hidden";

export default function ImageGallery() {
    const images = [
        {
            'id': 1,
            'url': 'https://images.pexels.com/photos/209948/pexels-photo-209948.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-1',
            'alt': 'Showing 1'
        },
        {
            'id': 2,
            'url': 'https://images.pexels.com/photos/9419244/pexels-photo-9419244.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-3',
            'alt': 'Showing 1'
        },
        {
            'id': 3,
            'url': 'https://images.pexels.com/photos/25752628/pexels-photo-25752628/free-photo-of-brown-dog-covered-with-snow.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-2',
            'alt': 'Showing 1'
        },
        {
            'id': 4,
            'url': 'https://images.pexels.com/photos/142497/pexels-photo-142497.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-2',
            'alt': 'Showing 1'
        },
        {
            'id': 5,
            'url': 'https://images.pexels.com/photos/2883380/pexels-photo-2883380.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-2 row-span-1',
            'alt': 'Showing 1'
        },
        {
            'id': 6,
            'url': 'https://images.pexels.com/photos/1767434/pexels-photo-1767434.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-1 row-span-1',
            'alt': 'Showing 1'
        },
        {
            'id': 7,
            'url': 'https://images.pexels.com/photos/2883380/pexels-photo-2883380.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-1 row-span-1',
            'alt': 'Showing 1'
        },
        {
            'id': 8,
            'url': 'https://images.pexels.com/photos/33192/paddle-wheel-bucket-wheel-excavators-brown-coal-open-pit-mining.jpg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-3 row-span-1',
            'alt': 'Showing 1'
        },
        {
            'id': 9,
            'url': 'https://images.pexels.com/photos/2883380/pexels-photo-2883380.jpeg?auto=compress&cs=tinysrgb&w=1200',
            'span': 'col-span-1',
            'alt': 'Showing 1'
        },
    ]

    return (
        <div className="px-5 mb-20 lg:mb-0 lg:px-0 lg:w-1/2 lg:ml-32 container relative">

            <div className='w-full absolute h-[660px] border border-troto-rose-1/50 rounded-xl z-10 rotate-[-6deg] translate-x-[-28px] translate-y-[-80px] bg-troto-4/10 pointer-events-none'></div>
            <div className='w-full absolute h-[660px] border border-troto-rose-1/50 rounded-xl z-1 rotate-[5deg] translate-x-[30px] translate-y-[-80px] bg-troto-rose-1/10 pointer-events-none'></div>

            <div className='mt-16 z-10'>
                <div className="grid grid-cols-4 gap-4 space-x-1">
                    {
                        images.map((image, index) => {
                            return <div className={`${image.span}  ${common_properties}`}>
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1} // Number of slides visible at the same time
                                    autoplay={{ delay: 2000 + (index * Math.random()) * 1000 }}
                                    modules={[Autoplay]} // Add Autoplay module
                                    className="mySwiper_"
                                >
                                    <SwiperSlide className={`w-full ${element_height} ${image.height}`}>
                                        <img
                                            src={image.url}
                                            alt={image.alt}
                                            className="w-full h-full object-cover rounded-lg"
                                        />
                                    </SwiperSlide>
                                    <SwiperSlide className={`w-full ${element_height} ${image.height}`}>
                                        <img
                                            src={image.url}
                                            alt={image.alt}
                                            className="w-full h-full object-cover rounded-lg"
                                        />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
};