import { XMarkIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export default function VideoPlayer(props) {
    const url = props.url || `https://troto3-files.s3.af-south-1.amazonaws.com/extract/original/efrAmFOipi3250081718956843585KLcXOmT5363.mp4`;
    const [is_open, set_is_open] = useState(true);

    const handle_close_video_player = () => {
        set_is_open(false);
        if (props.fn_state && props.fn_state === "function") {
            props.fn_state({
                is_open: false,
            });
        }
    }

    return (
        <div>{is_open && <div className="fixed mt-16 top-0 z-50 w-full h-96 text-center">
            <div
                className="inline mx-auto bg-white mt-5 fixed top-16 p-4 rounded-full"
                onClick={handle_close_video_player}>
                <XMarkIcon className="h-5 w-5 z-50 text-red-500 mx-auto top-4" />
            </div>
            <video className="w-full h-full object-cover" controls>
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>}
        </div>
    )
} 