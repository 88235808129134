import i18next from "../../../i18next";
import SearchContentGrid from "../../../shared_components/Widgets/search_content_grid/SearchContentGrid";
import SearchContentList from "../../../shared_components/Widgets/search_content_list/SearchContentList";
const { t } = i18next;

export default function UserContents(props) {
    // console.log(props.content.data.data.user.all_media); 
    return <div className="px-4 py-2 border-l border-troto-3 mx-2 relative">
        <div className="lg:fixed lg:w-full bg-troto-1">
            <div className="text-troto-6 text-xl text-center lg:text-left lg:text-3xl ">
                {t("contents_and_suggestions")}
            </div>
            <hr className="relative left-[-20px] my-4 border-troto-3" />
        </div>

        <div className="mt-16 lg:overflow-scroll lg:h-screen lg:pb-32 lg:mt-20">
            <SearchContentList data={props.content.data.all_media} />
        </div>
    </div>;
}