import { useState } from "react";
import SearchContentListElt from "./components/SearchContenuListElt";
import SearchDossierListElt from "./components/SearchDossierListElt";

export default function SearchContentList(props) {
    const all_media = props.data;
    console.log("ALL THE MEDIA ", all_media);

    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

    const handlePlayPause = (id) => {
        if (currentlyPlaying === id) {
            setCurrentlyPlaying(null);  // If currently playing, then pause
        } else {
            setCurrentlyPlaying(id);  // Update to the new playing media id
        }
    }

    return <div>
        <ul>
            {all_media.map((media, index) => {
                // console.log(media);
                return <li key={"search_content" + media.type + index}>
                    {media.type === "media" && <SearchContentListElt  data={media.item} isPlaying={currentlyPlaying === media.id} _media_id={media.id} onPlayPause={(_id) => handlePlayPause(_id)}/>}
                    {media.type === "album" && <SearchDossierListElt data={media.item} />}
                </li>
            })}
        </ul>
    </div>;
}