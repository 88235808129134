import i18next from '../../i18next';

import GooglePlayIcon from '../../assets/img/google_play_icon.png';
import AppStoreIcon from '../../assets/img/apple_icon.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import SlidableRow from './components/SlidableRow';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import ImageGallery from './components/ImageGallery';
// import Description from '../linklanding/components/Description';
import Header from './components/Header';

import x_dark_icon from '../../assets/img/socials-dark/x.png';
import youtube_dark_icon from '../../assets/img/socials-dark/youtube.webp';
import facebook_dark_icon from '../../assets/img/socials-dark/facebook.webp';
import instagram_dark_icon from '../../assets/img/socials-dark/instagram.webp';
import tiktok_dark_icon from '../../assets/img/socials-dark/tiktok.webp';

const { t } = i18next;

export default function HomePage() {

    const social_media_links = [
        {
            title: 'Facebook',
            link: 'https://www.facebook.com/trotoapp',
            icon: {
                'light': 'https://www.facebook.com/images/fb_icon_325x325.png',
                'dark': facebook_dark_icon
            }
        },
        {
            title: 'Instagram',
            link: 'https://www.instagram.com/trotoapp',
            icon: {
                'light': 'https://www.instagram.com/static/images/ico/favicon-192.png/68d99ba29cc8.png',
                'dark': instagram_dark_icon
            }
        },
        {
            title: 'X',
            link: 'https://www.twitter.com/trotoapp',
            icon: {
                'light': 'https://abs.twimg.com/favicons/twitter.ico',
                'dark': x_dark_icon
            }
        },
        {
            title: 'Youtube',
            link: 'https://www.youtube.com/@trotoapp',
            icon: {
                'light': 'https://www.youtube.com/s/desktop/3c7b8b9b/img/favicon_32.png',
                'dark': youtube_dark_icon
            }
        },
        {
            title: 'Tiktok',
            link: 'https://www.tiktok.com/@trotoapp',
            icon: {
                'light': 'https://www.tiktok.com/favicon.ico',
                'dark': tiktok_dark_icon
            }
        }
    ];

    const categories_to_explore = [
        [
            { title: 'Agriculture', ref: 'agriculture' },
            { title: 'Peche', ref: 'agriculture1' },
            { title: 'Kivunde', ref: 'agriculture2' },
            { title: 'Kih', ref: 'agriculture3' },
            { title: 'Agriculture', ref: 'agriculture2' },
            { title: 'Agriculture', ref: 'agriculture3' },
            { title: 'Agriculture', ref: 'agriculture' },
            { title: 'Peche', ref: 'agriculture1' },
            { title: 'Kivunde', ref: 'agriculture2' },
            { title: 'Kih', ref: 'agriculture3' },
            { title: 'Agriculture', ref: 'agriculture2' },
            { title: 'Agriculture', ref: 'agriculture3' },
        ],
        [
            { title: 'Kivunde', ref: 'agriculture2' },
            { title: 'Kih', ref: 'agriculture3' },
            { title: 'Agriculture', ref: 'agriculture2' },
            { title: 'Agriculture', ref: 'agriculture3' },
            { title: 'Agriculture', ref: 'agriculture2' },
            { title: 'Agriculture', ref: 'agriculture3' },
            { title: 'Agriculture', ref: 'agriculture' },
            { title: 'Peche', ref: 'agriculture1' },
            { title: 'Kivunde', ref: 'agriculture2' },
            { title: 'Kih', ref: 'agriculture3' },
            { title: 'Agriculture', ref: 'agriculture2' },
            { title: 'Agriculture', ref: 'agriculture3' },
        ]
    ]

    return (
        <div className='w-full overflow-x-hidden'>
            {/* Header */}
            <Header />

            {/* <PopupLayout /> */}

            {/* Body */}
            <div className='pl-4 relative'>
                <div className='lg:flex'>
                    <div className='lg:w-1/2 lg:inline-block'>
                        {/* Zone 1 */}
                        <div className=' basis-1/2 box-border'>
                            <div className='text-4xl pr-3 lg:pr-0 lg:text-5xl font-extrabold mt-10 lg:mt-20 mb-10'>
                                <div>Se divertir partout,</div>
                                <div className='font-raleway'>Se former dans sa langue</div>
                            </div>

                            <div className='my-10'>
                                <div className='text-md lg:text-lg my-2 pr-6 lg:pr-0'>Téléchargez maintenant l’application pour votre téléphone</div>
                                <div className='my-4 font-semibold'>
                                    <a href="https://play.google.com/store/apps/details?id=com.nfinic.troto">
                                        <div className='inline text-md pl-2 pr-4 lg:pr-10 py-4 relative bg-troto-black-1 text-white rounded-lg mr-2'>
                                            <img src={GooglePlayIcon} className='h-6 relative bottom-0.5 inline mx-1 lg:mx-3 font-montserrat' alt='Google_play_icon' /> <span className='font-montserrat'>Google Play</span>
                                        </div>
                                    </a>
                                    <a href="https://apps.apple.com/us/app/troto/id1551991128">
                                        <div className='inline text-md  pl-2 pr-4 lg:pr-8 pl-4 py-4 bg-troto-black-1 text-white rounded-lg mx-2'>
                                            <img src={AppStoreIcon} className='h-8 inline relative bottom-1' alt='App_store_icon' /> <span className='font-montserrat'>App store</span>
                                        </div>
                                    </a>
                                </div>
                            </div>



                            <div className='my-20 font-monserrat w-full'>
                                <div className='container overflow-hidden'>
                                    <div className='w-full'>
                                        {
                                            categories_to_explore.map((categories, index_c) => {
                                                return <Swiper
                                                    key={`swiper_${index_c}`}
                                                    spaceBetween={10}
                                                    slidesPerView={4} // Number of slides visible at the same time
                                                    autoplay={{ delay: 2000 + index_c * 1000 }}
                                                    modules={[Autoplay]} // Add Autoplay module
                                                    className="mySwiper"
                                                >
                                                    {categories.map(({ title }, index) => (
                                                        <SwiperSlide key={`cat_${index}_${index_c}`} className={`flex-none my-2 text-xs lg:text-md text-troto-border-color border border-troto-border-color-2 shadow shadow-troto-shadow-color/25 px-2 lg:px-4 py-2 rounded-full`}>
                                                            {title}
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper >
                                            })
                                        }


                                    </div>
                                </div>
                                {/* {
                                categories_to_explore.map((category, index) => {
                                    return <SlidableRow  key={index} categories={category} />
                                })
                            } */}
                            </div>
                        </div>

                    </div>

                    {/* Zone 2 */}
                    <div className='mt-40 lg:mt-0 lg:w-1/2 lg:inline-block'>
                        <div className='container'>
                            <ImageGallery />
                        </div>
                    </div>

                </div>


                <div className='pb-5 lg:pb-10 lg:mb-0 text-xl text-white font-bold'>
                    <div>Accédez à la musique, aux formations, aux radios </div>
                    <div>Partagez vos contenus avec passion pour un monde meilleur</div>
                    <div className='text-sm font-light font-sans py-2'>&copy; {new Date().getFullYear()}</div>
                </div>

                <div className='mb-10 lg:mb-0 lg:fixed bg-troto-dark-1  p-4 bottom-5 right-5'>
                    <ul className='flex space-x-6'>
                        {
                            social_media_links.map((social_media, index) => {
                                return <a key={index} href={social_media.link} target='_blank'>
                                    <li>
                                        <img src={social_media.icon['dark']} className='h-5' alt={social_media.title} />
                                    </li>
                                </a>;
                            })
                        }
                    </ul>
                </div>

            </div>
        </div>
    )
}