import { parseISO, format } from 'date-fns';

export function from_float_seconds_to_mm_ss(seconds){
  // alert(seconds);
    const date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
}

export function _formatDate(dateString) {
  const date = parseISO(dateString);
  return format(date, 'MMMM dd, yyyy');
}

// // Usage
// const formattedDate = formatDate("2024-07-02T10:10:31.000+00:00");
// console.log(formattedDate); // Outputs: July 02, 2024