import { BrowserRouter,  Routes, Route} from "react-router-dom";
import HomePage from "./pages/homepage/HomePage";
import LinkLanding from "./pages/linklanding/LinkLanding";
import TestPage from "./pages/TestPage";
import DeleteUserData from "./pages/delete_user_data/DeleteUserData";


export default function MainRouter(){
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/:content_type/:content_id" element={<LinkLanding/>}/>
                    <Route path="/testpage" element={<TestPage/>}/>
                    <Route path="/data-deletion" element={<DeleteUserData/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}