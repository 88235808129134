import { PauseIcon, PlayIcon } from "@heroicons/react/16/solid";
import { _formatDate, from_float_seconds_to_mm_ss } from "../../../../utils/time_displayer";
import audio_default_image from '../../../../assets/img/default.artwork.audio.png';
import video_default_image from '../../../../assets/img/default.artwork.audio.png';
import { useState } from "react";
import { get_this_server_url } from "../../../../utils/local_server";
import { encrypt_param_id } from "../../../../utils/url_id";

export default function SearchContentListElt(props) {
    const media = props.data.excerpt_link ? props.data : props.data.media;
    // alert(JSON.stringify(media));
    const { isPlaying, onPlayPause, _media_id } = props;
    const media_url = media.excerpt_link;

    const handle_play_pause = (type_media) => {
        if (type_media === "audio") {
            if (window._audio_player_object) {
                if (window._audio_player_object.src === media_url) {
                    if (!window._audio_player_object.paused) {
                        window._audio_player_object.pause();
                        onPlayPause(null);
                    } else {
                        window._audio_player_object.play();
                        onPlayPause(_media_id);
                    }

                } else {
                    window._audio_player_object.pause();
                    window._audio_player_object = new Audio(media_url);
                    window._audio_player_object.addEventListener("ended", () => {
                        onPlayPause(null);
                    });
                    window._audio_player_object.play();
                    onPlayPause(_media_id);
                }
            } else {
                window._audio_player_object = new Audio(media_url);
                window._audio_player_object.play();
                onPlayPause(_media_id);

                window._audio_player_object.addEventListener("ended", () => {
                    onPlayPause(null);
                });

            }
        } else if ( type_media === "video") {
            if (window._audio_player_object) {
                window._audio_player_object.pause();
            }
            
            onPlayPause(_media_id);
        }
    }

    console.log(media);

    return <div className="bg-troto-1 border border-troto-2/80 rounded-md p-2 m-1 mb-2">
        <div className="pb-1 text-sm text-troto-4">
            {/* <time dateTime={media.date_enregistrement}>{_formatDate(media.date_enregistrement)}</time> */}
        </div>

        <div className="flex gap-x-4">
            <div>
                {media.type_media === "audio" && <div className="w-[120px] h-[120px] overflow-hidden relative lg:rounded-lg ">
                        <img className="absolute inset-0 object-cover h-full w-full" src={media.artwork_image} onError={(e) => e.target.src = media.type_media && media.type_media === "video" ? video_default_image : audio_default_image} alt="" />
                    </div>}

                 {media.type_media === "video" && <span>{!isPlaying ?  <div className="w-[120px] h-[120px] overflow-hidden relative lg:rounded-lg ">
                        <img className="absolute inset-0 object-cover h-full w-full" src={media.artwork_image} onError={(e) => e.target.src = media.type_media && media.type_media === "video" ? video_default_image : audio_default_image} alt="" />
                    </div> : <div className="w-[120px] h-[120px] overflow-hidden relative lg:rounded-lg ">
                        <video className="absolute inset-0 object-cover h-full w-full" src={media.excerpt_link} autoPlay alt="" />
                    </div>
                }</span>}
            </div>
            <div className="flex-auto">
                <div className="flex items-baseline justify-between gap-x-4">
                    <div className="text-xl leading-6 font-bold text-troto-5"><a href={`${get_this_server_url()}/c/${encrypt_param_id(media.idMedia)}`}>{media.titre}</a></div>
                </div>
                <div className="mt-1 my-1 line-clamp-2 text-sm leading-6 text-troto-4">
                    {media.description ? media.description : "Aucune description"}
                </div>

                <div className="my-1">
                    <div className="text-sm">{from_float_seconds_to_mm_ss(media.duree)}</div>
                </div>

                <div className="mt-1 mb-2">
                    <span className="text-troto-rose-1 cursor-pointer hover:font-bold" onClick={() => handle_play_pause(media.type_media)}>
                        {isPlaying ? <span><PauseIcon className="h-5 inline relative top-[-2px]" /> Pause </span> : <span><PlayIcon className="h-5 inline relative top-[-2px]" /> Play </span>}

                    </span>
                    {/* <span className="mx-3"> / </span> <span className="text-troto-rose-1"> Show notes </span> */}
                </div>
                <div>{isPlaying}</div>
            </div>
        </div>
    </div>
}