import TrotoLogo from '../../../assets/img/logo_white.png';
import { Bars3Icon, CreditCardIcon, GlobeAltIcon, UserIcon, UsersIcon } from '@heroicons/react/24/outline';

import clsx from 'clsx';
// import PopupLayout from './components/PopupLayout';
import { Button, Description, Field, Input, Label, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

export default function Header() {
    

    return <>
        <div className="flex justify-between py-4 px-2 w-full bg-gradient-to-b from-troto-dark-1/70 via-troto-1-50 to-transparent z-10">
            <div className='flex-2'><img src={TrotoLogo} alt="Logo" className='h-16' /></div>

            <div className='hidden lg:block'><WebsiteMenus /></div>
            
            <div className='text-sm mt-2 inline text-troto-5 px-4 py-2 lg:hidden'>
                <Popover>
                    <PopoverButton>
                        <Bars3Icon className='h-7' />
                    </PopoverButton>
                    <PopoverPanel
                        transition
                        anchor="bottom"
                        className="divide-y text-troto-5 z-[300] w-[400px] divide-white/5 rounded-xl bg-troto-dark-1 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                    >
                        <div className='min-h-[300px]'>
                            <WebsiteMenus />
                        </div>
                    </PopoverPanel>
                </Popover>
            </div>
        </div>
    </>;
}

function WebsiteMenus(){
    const about_troto_common_div = 'bg-white/5 shadow p-2 mr-4 my-2 rounded-lg text-sm text-troto-5';
    const icons_common_properties = 'h-6 text-troto-rose-1 inline';
    const about_troto_properties = [
        {
            text: 'Améliorer la vie de tout le monde',
            icon: <UserIcon className={icons_common_properties} />
        },
        {
            text: 'Donnez accès à 1000 langues',
            icon: <GlobeAltIcon className={icons_common_properties} />
        },
        {
            text: 'Marché commun des contenus',
            icon: <CreditCardIcon className={icons_common_properties} />
        },
        {
            text: 'Payements sécurisés',
            icon: <CreditCardIcon className={icons_common_properties} />
        },
        {
            text: 'Plusieurs créateurs de contenus',
            icon: <UsersIcon className={icons_common_properties} />
        }
    ]

    return <>
        <div className='mt-4 flex-1 pr-16'>
                <ul className='ml-10 lg:ml-0 lg:flex lg:space-x-8 lg:justify-center'>
                    <li className='z-[300]'>
                        <Popover>
                            <PopoverButton className="block focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
                                <span className='py-4 block lg:inline lg:py-0'>Payer son abonnement <div className='ml-8 hidden lg:inline'>&middot;</div></span>
                            </PopoverButton>
                            <PopoverPanel
                                transition
                                anchor="bottom"
                                className="divide-y z-[300] divide-white/5 rounded-xl bg-troto-dark-1 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                            >

                                <div className="p-3">
                                    <div className="w-full max-w-md px-4">
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Votre numero / adresse email</Label>
                                            <Description className="text-sm/6 text-white/50">Entrez votre identifiant sur Troto</Description>
                                            <Input
                                                className={clsx(
                                                    'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3  text-white',
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                )}
                                                type="text"
                                            />
                                            <Button className="mt-3 w-full py-2 rounded-lg bg-troto-rose-1 text-white text-sm/6 font-medium">Continuer</Button>
                                        </Field>
                                    </div>
                                </div>
                            </PopoverPanel>
                        </Popover>
                    </li>
                    <li className='z-[300]'>
                        <Popover>
                            <PopoverButton className="block focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
                                <span className='py-4 block lg:inline lg:py-0'>Nous contacter  <div className='ml-8 hidden lg:inline'>&middot;</div></span>
                            </PopoverButton>
                            <PopoverPanel
                                transition
                                anchor="bottom"
                                className="divide-y z-[300] w-[400px] divide-white/5 rounded-xl bg-troto-dark-1 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                            >
                                <div className="p-3">
                                    <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="tel:+243971108400">
                                        <p className="font-semibold text-white">Téléphone</p>
                                        <p className="text-white/50">+243 971 108 400</p>
                                    </a>
                                    <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="whatsapp://+243971108400">
                                        <p className="font-semibold text-white">WhatsApp</p>
                                        <p className="text-white/50">+243 971 108 400</p>
                                    </a>
                                    <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="mailto:info@troto.co">
                                        <p className="font-semibold text-white">Email</p>
                                        <p className="text-white/50">info@troto.co</p>
                                    </a>
                                    <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="#">
                                        <p className="font-semibold text-white">Écrire maintenant</p>
                                    </a>
                                </div>

                            </PopoverPanel>
                        </Popover>
                    </li>
                    <li className='z-[300]'>
                        <Popover>
                            <PopoverButton className="block focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
                                <span className='py-4 block lg:inline lg:py-0'>C'est quoi Troto?  <div className='ml-8 hidden lg:block'>&middot;</div></span>
                            </PopoverButton>
                            <PopoverPanel
                                transition
                                anchor="bottom"
                                className="divide-y shadow shadow-troto-5 text-white z-[300] divide-white/5 rounded-xl bg-troto-dark-1 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                            >
                                <div className='text-xl w-[400px] text-center h-[500px] shadow py-10'>
                                    <div className='text-[20px] font-bold  text-troto-5 mx-10'>Au délà d'une simple application</div>
                                    <div className='grid grid-cols-2 text-sm text-center pl-4'>
                                        {
                                            about_troto_properties.map((property, index) => {
                                                return (
                                                    <div key={index} className={about_troto_common_div}>
                                                        <div className='my-2'>{property.icon}</div>
                                                        {property.text}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </PopoverPanel>
                        </Popover>
                    </li>

                </ul>
            </div>
    </>
}