import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { _play_audio } from "../../../utils/audio_player";

import dossier_default_image from '../../../assets/img/default.artwork.dossier.png';
import utilisateur_default_image from '../../../assets/img/default.artwork.user.png';
import video_default_image from '../../../assets/img/default.artwork.video.png';
import audio_default_image from '../../../assets/img/default.artwork.audio.png';
import artist_default_image from '../../../assets/img/default.artwork.creator.png';
import VideoPlayer from "../../../shared_components/Widgets/VideoPlayer";

export default function BackgroundImg(props) {
    const { content } = props;

    let media_files = null;
    if (content && (content.type === "utilisateur" || content.type === "artiste") && content.data && content.data.all_media) {
        media_files = content.data.all_media;
    }

    const [video_controller, set_video_controller] = useState({
        is_open : false,
    });

    // console.log(content);

    const [this_song_playing, set_this_song_playing] = useState(null);
    const [is_playing_song, set_is_playing_song] = useState(false);
    const [force_pause, set_force_pause] = useState(false);
    const [audio_en_cours, set_audio_en_cours] = useState(0);

    const get_img_url = (type) => {
        if (type === "contenu") {
            if (content.data._media) return content.data._media.artwork_image;
            else return content.data.media.artwork_image;
        } else if (type === "dossier") {
            return content.data.artwork;
        } else if (type === "utilisateur") {
            return content.data.avatar;
        } else if (type === "artiste") {
            return content.data.avatar;
        }
        return null;
    }

    const get_default_img_url = (type) => {
        if (type === "contenu") {
            // console.log(content.data);
            return audio_default_image;
        } else if (type === "dossier") {
            return dossier_default_image;
        } else if (type === "utilisateur") {
            return utilisateur_default_image;
        } else if (type === "artiste") {
            return utilisateur_default_image;
        }
        return null;
    }

    const get_media_obj = (type) => {
        if (type === "contenu") {
            return content.data._media ? content.data._media : content.data.media;
        } else if (type === "dossier") {
            return content.data;
        } else if (type === "utilisateur") {
            return content.data;
        } else if (type === "artiste") {
            return content.data;
        }
        return null;
    }

    const media_obj = get_media_obj(content.type);

    const get_title = (type) => {
        if (type === "contenu") {
            return content.data._media ? content.data._media.titre : content.data.media.titre;
        } else if (type === "dossier") {
            return content.data.titre;
        } else if (type === "utilisateur") {
            return content.data.noms;
        } else if (type === "artiste") {
            return content.data.pseudo;
        }
        return null;
    }


    const handle_play_audio = (type) => {
        if (type === "contenu") {
            let is_audio = content.data._media ? content.data._media.type_media === "audio" : content.data.media.type_media === "audio";
            if(is_audio){
                if (content.data._media) {
                    _play_audio(content.data._media, set_is_playing_song);
                } else {
                    _play_audio(content.data.media, set_is_playing_song);
                }
            }else{
                set_video_controller({
                    is_open : true,
                });
            }
            
        } else if (type === "utilisateur") {
            console.log(media_files);
            _play_audio(media_files[2].media, set_is_playing_song);
        } else if (type === "artiste") {
            console.log(media_files);
            _play_audio(media_files[2].media, set_is_playing_song);
        }
    }

    // Canceled as we are removing the player widget
    // const handle_play_audio_CANCELED = (type) => {
    //     if (type === "contenu") {
    //         if (window && !window._audio_player_object) {
    //             const evt = new CustomEvent("play_music", {
    //                 detail: {
    //                     is_playing: true,
    //                     title: get_title(content.data._media.titre),
    //                     artwork: get_img_url(content.type),
    //                     media_id: content.data._media.idMedia
    //                 }
    //             });
    //             window.dispatchEvent(evt);

    //             window._audio_player_object = new Audio(content.data._media.excerpt_link);

    //             window._audio_player_object.play();

    //             set_this_song_playing(content.data._media.idMedia);
    //         } else if (window && window._audio_player_object && window._audio_player_object.src !== content.data._media.excerpt_link) {
    //             const evt = new CustomEvent("play_music", {
    //                 detail: {
    //                     is_playing: true,
    //                     title: get_title(content.data._media.titre),
    //                     artwork: get_img_url(content.type),
    //                     media_id: content.data._media.idMedia
    //                 }
    //             });
    //             window.dispatchEvent(evt);

    //             window._audio_player_object.pause();
    //             window._audio_player_object = new Audio(content.data._media.excerpt_link);

    //             window._audio_player_object.play();

    //             set_force_pause(true);
    //         } else if (window && window._audio_player_object && window._audio_player_object.src === content.data._media.excerpt_link) {
    //             window._audio_player_object.pause();

    //             const evt = new CustomEvent("play_music", {
    //                 detail: {
    //                     is_playing: false,
    //                     title: get_title(content.data._media.titre),
    //                     artwork: get_img_url(content.type),
    //                     media_id: content.data._media.idMedia
    //                 }
    //             });

    //             window.dispatchEvent(evt);
    //             set_force_pause(false);
    //         }

    //     }

    // }

    useEffect(() => {
        window.addEventListener("update_music_player", evt => {
            if (evt.detail.is_playing) {
                set_this_song_playing(null);
            } else {
                set_this_song_playing(content.data._media.idMedia);
            }
        });

        return () => {
            window.removeEventListener("update_music_player", evt => { });
        }
    });

    return <div className="relative z-2 ">
        {(content && content.success && content.data) ? <>
            {content.type === "contenu" && media_obj.type_media === "video" && video_controller && video_controller.is_open && <VideoPlayer url={media_obj.excerpt_link} fn_state={set_video_controller} />}
            <div className="h-[400px] overflow-hidden  lg:rounded-lg ">
                {content.data && content.data._media && content.data._media.type_media === "audio" && <img src={get_img_url(content.type)} className="cover" alt="Cover img" />}
                
                <img 
                    src={get_img_url(content.type)} 
                    onError={(e) => e.target.src = get_default_img_url(content.type)} 
                    className="cover mx-2" 
                    alt="Cover img" 
                />
                {/* { content.data && content.data._media && content.data._media.type_media === "video" && <img src={get_img_url(content.type)} className="cover" alt="Cover img" /> } */}
            </div>
            <div className="absolute bottom-0 text-white left-0 px-4 py-4 text-3xl bg-gradient-to-t from-troto-1 via-troto-1/60 via-troto-1/50 via-troto-1/20 to-transparent w-full ">{get_title(content.type)}</div>

            {(content.type === "contenu") && <div className="absolute right-4 bottom-5 bg-troto-rose-1 p-3 rounded-full" onClick={evt => handle_play_audio(content.type)}>
                {is_playing_song ? <PauseIcon className="h-7 text-white inline animate-pulse" /> : <PlayIcon className="h-7 text-white inline animate-pulse" />}
            </div>}

        </> : ''}

    </div>;
}