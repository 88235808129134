import { xhr_request } from "./main"

export const get_media_by_api = async (content_id) => {
    const res = await xhr_request(`medias/${content_id}`);
    return res;
}

// Dossier means folder in French. It can be an album, a playlist, a category, etc.
export const get_dossier_by_api = async (dossier_id) => {
    const res = await xhr_request(`album_2/${dossier_id}`);
    return res;
}