import { useEffect, useState } from "react";
import { get_this_server_url } from "../../../utils/local_server";
import { encrypt_param_id } from "../../../utils/url_id";
import { PlayIcon } from "@heroicons/react/20/solid";

export default function Description(props) {
    let { content } = props;
    let [display_text, set_display_text] = useState({
        description: "",
        createur_contenu: "",
        date_creation: "",
        type: "contenu",
        profil: ""
    });

    const get_createur_contenu = (type) => {
        if (type === "contenu") {
            if (!content.data) return;
            console.log(content.data);
            const media = content.data._media ? content.data._media : content.data.media; 

            set_display_text({
                createur_contenu: media.artist ? media.artist.pseudo : media.user.noms,
                description: media.lyrics,
                date_creation: media.publish_at,
                type: media.type,
                profil: media.artist ? media.artist.avatar : media.user.avatar,
                createur_id: media.artist ? media.artist.idInfoArtiste  :  media.user.idUtilisateur,
                type_owner : media.artist ? "artist" : "user"
            });
        } else if (type === "dossier") {
            if (!content.data) return;
            const dossier = content.data;
            set_display_text({
                createur_contenu: dossier.artist ? dossier.artist.pseudo : dossier.user.noms,
                description: dossier.description,
                date_creation: dossier.created_at,
                type: dossier.type,
                profil: dossier.artist ? dossier.artist.avatar : dossier.user.avatar,
                createur_id: dossier.artist ? dossier.artist.idInfoArtiste  :  dossier.user.idUtilisateur,
                type_owner : dossier.artist ? "artist" : "user"
            });
        }
    }

    useEffect(() => {
        console.log(content);
        get_createur_contenu(content.type);
    }, []);

    return <div className="pb-[30px]">
        <div className=" px-4 ">
            {content && content.type !== "utilisateur" && content.type !== "artiste" && <a href={`${get_this_server_url()}/${display_text.type_owner && display_text.type_owner === "artist" ? "a" : "u" }/${encrypt_param_id(display_text.createur_id)}`}><div className="text-troto-5 text-md">
                <img src={display_text.profil} className="inline bg-troto-4 p-1 my-2 mx-2 rounded-full h-5" alt="Profil d'utilisateur" /> {display_text.createur_contenu}
            </div></a>}
            <div className="text-troto-4 text-xs overflow-hidden">{display_text.description && display_text.description.length > 100 ? display_text.description.substring(0, 100) + "..." : display_text.description}</div>
            <div className="text-troto-4 text-md overflow-hidden">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget odio ultricies. Donec eget nunc nec nunc ultricies. 
                
            </div>

        </div>

    </div>;
}