import React from 'react';
import playStoreIcon from '../assets/img/google_play_icon.png';
import appStoreIcon from '../assets/img/apple_icon.png';
import image1 from '../assets/img/industria-metalurgia-2-1.jpeg';
import image2 from '../assets/img/industria-metalurgia-2-1.jpeg';
import image3 from '../assets/img/industria-metalurgia-2-1.jpeg';
import image4 from '../assets/img/industria-metalurgia-2-1.jpeg';
import image5 from '../assets/img/industria-metalurgia-2-1.jpeg';
import image6 from '../assets/img/industria-metalurgia-2-1.jpeg';
import image7 from '../assets/img/industria-metalurgia-2-1.jpeg';

const TestPage = () => {
    return (
      <div className="min-h-screen bg-black text-white flex flex-col items-center">
        <header className="w-full flex justify-between items-center p-4">
          <h1 className="text-3xl font-bold">trot</h1>
          <nav className="flex space-x-4">
            <a href="#" className="hover:underline">Payer son abonnement</a>
            <a href="#" className="hover:underline">Nous contacter</a>
            <a href="#" className="hover:underline">C’est quoi Troto ?</a>
          </nav>
          <div className="flex space-x-4">
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-xing"></i></a>
          </div>
        </header>
        <main className="flex flex-col items-center mt-10">
          <h2 className="text-4xl font-bold text-center mb-4">Se divertir partout, <br />Se former dans sa langue.</h2>
          <p className="text-center mb-6">Téléchargez maintenant l’application pour votre téléphone</p>
          <div className="flex space-x-4 mb-8">
            <a href="#" className="bg-black border-2 border-white px-4 py-2 rounded flex items-center space-x-2">
              <img src={playStoreIcon} alt="PlayStore" className="w-6 h-6"/>
              <span>PlayStore</span>
            </a>
            <a href="#" className="bg-black border-2 border-white px-4 py-2 rounded flex items-center space-x-2">
              <img src={appStoreIcon} alt="App Store" className="w-6 h-6"/>
              <span>App store</span>
            </a>
          </div>
          <div className="relative">
            <div className="absolute w-full h-full animate-swing1 border-2 border-red-500 top-0 left-0"></div>
            <div className="absolute w-full h-full animate-swing2 border-2 border-red-500 top-0 left-0"></div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 relative">
              <img src={image1} alt="Image 1" className="w-36 h-36"/>
              <img src={image2} alt="Image 2" className="w-36 h-36"/>
              <img src={image3} alt="Image 3" className="w-36 h-36"/>
              <img src={image4} alt="Image 4" className="w-36 h-36"/>
              <img src={image5} alt="Image 5" className="w-36 h-36"/>
              <img src={image6} alt="Image 6" className="w-36 h-36"/>
              <img src={image7} alt="Image 7" className="w-36 h-36"/>
            </div>
          </div>
        </main>
      </div>
    );
}

export default TestPage;
