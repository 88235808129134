import i18next from "../../../i18next";

import AppleIcon from "../../../assets/img/apple_icon.png";
import PlayStoreIcon from "../../../assets/img/google_play_icon.png";

const { t } = i18next;

export default function AppStores() {
    return <div className="text-center pb-4 text-white">
        <div className="font-bold py-2">{t("download_the_application")}</div>
        <div className="my-auto py-2">
            <div className="flex justify-center">
                <div className="bg-[#444] text-white text-xs px-4 py-2 pt-3 rounded-md mx-2">
                    <a href="#" target="_blank" rel="noreferrer noopener">
                        <img src={PlayStoreIcon} className="h-5 relative top-[-3px] mr-1 inline" alt="Apple Icon" />
                        {t("google_play")}
                    </a>
                </div>
                <div className="bg-[#444] text-xs text-white px-4 py-2 rounded-md mx-2">
                    <a href="https://apps.apple.com/au/app/troto/id1551991128" target="_blank" rel="noreferrer noopener">
                        <img src={AppleIcon} className="h-6 relative top-[-3px] mr-1 inline" alt="Apple Icon" />
                        {t("app_store")}
                    </a>
                </div>
            </div>
        </div>
        <div className="py-2 mx-5">
            <a href="https://" className="text-xs underline">{t("click_here_for_direct_android_app_download")}</a>
        </div>
    </div>;
}