import i18next from "../../../i18next";
import GridContenu from "../../../shared_components/Widgets/GridContenu";
import SearchContentGrid from "../../../shared_components/Widgets/search_content_grid/SearchContentGrid";
import DossierContentList from "./DossierContentList";
import ListSuggestionContenus from "./ListSuggestionContenus";
const { t } = i18next;

export default function Suggestions(props) {
    console.log("Suggestions", props.content.data.suggesions);
    return <div className="px-3">
        <div className="mx-5">
            {/* <GridContenu data={props.content.data.suggesions} /> */}
            <ListSuggestionContenus data={props.content.data.suggesions} />
        </div>
    </div>;
}