import i18next from 'i18next';
import fr from './locales/fr.json';

import LanguageDetector from 'i18next-browser-languagedetector';

const language = window.localStorage.getItem('language');

i18next
  .use(LanguageDetector)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng :  language || 'fr',
    failingLng: 'fr',
    // Using simple hardcoded resources for simple example
    resources: {
      fr: { translation: fr },
    },
  })

export default i18next;