import Hashids from "hashids";

const HASH_SALT = "bfa3033b-fa00-4bcc-b2d3-43ad4ee25860";
const HASH_LENGTH = 8;

const h = new  Hashids(HASH_SALT, HASH_LENGTH);

export const decrypt_param_id = (param_id) => {
    return param_id;
    const res = h.decode(param_id);
    if( res.length) {
        return res[0];
    }

    // return decrypted;
}

export const encrypt_param_id = (param_id) => {
    return param_id;
    const res = h.encode(param_id);
    if( res.length) {
        return res
    }
}