export function _play_audio(media, state_fn){
    if ( window._audio_player_object === null ){
        window._audio_player_object = new Audio(media.excerpt_link);
        window._audio_player_object.onpause = () => {
            state_fn(false);
        }

        window._audio_player_object.play();
        state_fn(true);
    } else if ( window._audio_player_object && window._audio_player_object.src !== media.excerpt_link){
        window._audio_player_object.pause();
        window._audio_player_object = new Audio(media.excerpt_link);
        window._audio_player_object.onpause = () => {
            state_fn(false);
        }

        window._audio_player_object.play();
        state_fn(true);
        

        
    } else if ( window._audio_player_object && window._audio_player_object.src === media.excerpt_link){
        if ( window._audio_player_object.paused ){
            window._audio_player_object.play();
            state_fn(true);
        } else {
            window._audio_player_object.pause();
            state_fn(false);
        }
    }
}