import { useState } from "react";
import SearchContentListElt from "./components/SearchContenuListElt";
import SearchDossierListElt from "./components/SearchDossierListElt";

export default function SearchDirectContentList(props) {
    const all_media = props.data;
    console.log("ALL THE MEDIA ", all_media);

    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

    const handlePlayPause = (id) => {
        if (currentlyPlaying === id) {
            setCurrentlyPlaying(null);  // If currently playing, then pause
        } else {
            setCurrentlyPlaying(id);  // Update to the new playing media id
        }
    }

    return <div>
        <ul>
            {all_media.map((media, index) => {
                console.log(media);
                return <li key={media.idMedia}>
                    {media.idMedia && <SearchContentListElt  data={{media}} isPlaying={currentlyPlaying === media.idMedia} _media_id={media.idMedia} onPlayPause={(_id) => handlePlayPause(_id)}/>}
                    {media.type === "album" && <SearchDossierListElt data={{media}} />}
                </li>
            })}
        </ul>
    </div>;
}