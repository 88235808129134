import { useState } from "react";
import SearchContentListElt from "./components/SearchContenuListElt";
import SearchDossierListElt from "./components/SearchDossierListElt";

export default function SearchContentDossierList(props) {
    const all_media = props.data;
    console.log("ALL THE MEDIA ", all_media);

    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

    const handlePlayPause = (id) => {
        if (currentlyPlaying === id) {
            setCurrentlyPlaying(null);  // If currently playing, then pause
        } else {
            setCurrentlyPlaying(id);  // Update to the new playing media id
        }
    }

    return <div>
        <ul>
            {all_media.map((media, index) => {
                // console.log(media);
                const li_item_key = `alb_key_${media.type === "media" ? media.item.idMedia :  media.item.idAlbum}`;
                return <li key={li_item_key}>
                    {media.type === "media" && <SearchContentListElt  data={media.item} isPlaying={currentlyPlaying === li_item_key} _media_id={media.item.id} onPlayPause={(_id) => handlePlayPause(_id)}/>}
                    {media.type === "album" && <SearchDossierListElt data={media.item} />}
                </li>
            })}
        </ul>
    </div>;
}