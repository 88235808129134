import i18next from "../i18next";

const get_language = () => {    ;
    return i18next.language;
}

export const xhr_request = async (end_point, type = "GET", auth = false, payload) => {
    let options = {
        method: type,
        headers: {}  
    };

    options.headers['Content-Type'] = 'application/json';
    options.headers['Accept-Language'] = get_language();

    if(payload){
        options.body = JSON.stringify(payload);
    }
    
    if(auth === true){
        options.headers['Authorization'] = `Bearer ${localStorage.token}`;
    }

    const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/${end_point}`, options);
    const data = await res.json();
    return data; 
}