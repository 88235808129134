import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid";
import i18next from "../../../i18next";
import { ContentPlayButton } from "../../ContentPlayButton";
import { FormattedDate } from "../../FormattedDate";
import SearchContenu from "./components/SearchContenu";
import SearchDossier from "./components/SearchDossier";

const { t } = i18next;

export default function SearchContentGrid(props) {
  const { data } = props;
  console.log(data);
  return (
    <ul role="list" className="">
      {data && data.length > 0 ? <>
        {data.map((media) => {
          return <li key={media.id}>
            {media.type === "media" && <div>
              {/* <div>{JSON.stringify(media)}</div> */}
                <div className="flex flex-col items-start">
                  <h2
                    
                    className="mt-2 text-lg font-bold text-troto-5"
                  >
                    {media.titre}
                  </h2>
                  <FormattedDate
                    date={new Date()}
                    className="order-first font-mono text-sm leading-7 text-slate-500"
                  />
                  <p className="mt-1 text-base leading-7 text-slate-700">
                    {media.description}
                  </p>
                  <div className="mt-4 flex items-center gap-4">
                    <ContentPlayButton
                      episode={"Yes"}
                      className="flex items-center gap-x-3 text-sm font-bold leading-6 text-pink-500 hover:text-pink-700 active:text-pink-900"
                      playing={
                        <>
                          <PauseIcon className="h-2.5 w-2.5 fill-current" />
                          <span aria-hidden="true">Listen</span>
                        </>
                      }
                      paused={
                        <>
                          <PlayIcon className="h-2.5 w-2.5 fill-current" />
                          <span aria-hidden="true">Listen</span>
                        </>
                      }
                    />
                    <span
                      aria-hidden="true"
                      className="text-sm font-bold text-slate-400"
                    >
                      /
                    </span>
                    <a
                      href={`#}`}
                      className="flex items-center text-sm font-bold leading-6 text-pink-500 hover:text-pink-700 active:text-pink-900"
                      aria-label={`Show notes for episode 2`}
                    >
                      Go to element
                    </a>
                  </div>
                </div>
            </div>}
            {media.type === "album" && <div>

            </div>}
          </li>
        })
        }
      </> : <div>{t("no_data_to_display")}</div>}
    </ul>
  )
}
