import i18next from '../../../i18next';

import TrotoLogo from '../../../assets/img/logo_white.png';

const { t } = i18next;

export default function Header(){
    // create a white gradient background from 90% to 60%, then a white background from 60% to 0%
    return <div className=" py-4 px-2 absolute w-full
        bg-gradient-to-b from-troto-1/70 via-troto-1-50 to-transparent z-10
    ">
        <div className='relative inline-block left-[-25px] top-[-15px]'><img src={TrotoLogo} alt="Logo" className='h-16'/></div>
        <div className='float-right text-sm inline bg-troto-1 text-troto-5 px-4 py-2 rounded-md'>{t("open_in_app")}</div>
    </div>;
}