import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { get_dossier_by_api, get_media_by_api } from "../../api/media";
import { get_user_by_api } from "../../api/user";
import { decrypt_param_id } from "../../utils/url_id";
import Header from "./components/Header";
import BackgroundImg from "./components/BackgroundImg";
import Description from "./components/Description";
import { Parallax } from "react-scroll-parallax";
import AppStores from "./components/AppStores";
import Suggestions from "./components/Suggestions";
import UserContents from "./components/UserContents";
import DossierContentList from "./components/DossierContentList";
import PlayerWidget from "../../shared_components/Widgets/PlayerWidget";
import VideoPlayer from "../../shared_components/Widgets/VideoPlayer";
import { get_artist_by_api } from "../../api/artist";

export default function LinkLanding(props) {
    const params = useParams();
    const content_type = params.content_type;
    const content_id = decrypt_param_id(params.content_id);

    const [link_content, set_link_content] = useState({
        is_loading: true,
        type: null,
        data: null,
        id: null,
        success: false
    });


    const _get_media = async (content_id) => {
        try {
            set_link_content({
                is_loading: true,
                type: "contenu",
                data: null,
                id: content_id,
                success: false
            });
            const res = await get_media_by_api(content_id);
            set_link_content({
                is_loading: false,
                type: "contenu",
                data: res.data,
                id: content_id,
                success: true
            });
        } catch (e) {
            console.log(e);
            set_link_content({
                is_loading: false,
                type: "",
                data: null,
                id: content_id,
                success: false
            });
        }
    }

    const _get_user = async (user_id) => {
        try {
            set_link_content({
                is_loading: true,
                type: "utilisateur",
                data: null,
                id: content_id,
                success: false
            });

            const res = await get_user_by_api(user_id);
            set_link_content({
                is_loading: false,
                type: "utilisateur",
                data: res.data,
                id: content_id,
                success: true
            });
            return res;
        } catch (e) {
            console.log(e);
            set_link_content({
                is_loading: false,
                type: "utilisateur",
                data: null,
                id: content_id,
                success: false
            });
        }
    }

    const _get_dossier = async (dossier_id) => {
        try {
            set_link_content({
                is_loading: true,
                type: "dossier",
                data: null,
                id: content_id,
                success: false
            });
            const res = await get_dossier_by_api(dossier_id);
            set_link_content({
                is_loading: false,
                type: "dossier",
                data: res.data,
                id: content_id,
                success: true
            });
            return res;
        } catch (e) {
            console.log(e);
            set_link_content({
                is_loading: false,
                type: "dossier",
                data: null,
                id: content_id,
                success: false
            });
        }
    }

    const _get_artist = async (artist_id) => {
        try {
            set_link_content({
                is_loading: true,
                type: "artiste",
                data: null,
                id: content_id,
                success: false
            });
            const res = await get_artist_by_api(artist_id);
            set_link_content({
                is_loading: false,
                type: "artiste",
                data: res.data,
                id: content_id,
                success: true
            });
            return res;
        } catch (e) {
            console.log(e);
            set_link_content({
                is_loading: false,
                type: "artiste",
                data: null,
                id: content_id,
                success: false
            });
        }
    }

    useEffect(() => {
        console.log(content_type, content_id);
        switch (content_type) {
            case "c":
                _get_media(content_id);
                break;
            case "u":
                _get_user(content_id);
                break;
            case "d":
                _get_dossier(content_id);
                break;
            case "a":
                _get_artist(content_id);
                break;
            
            default:
                break;
        }
    }, []);

    return <>
        <div>
            {link_content.is_loading && <h1>Loading...</h1>}
            {link_content.is_loading === false && link_content.success === false && <h1>Not found</h1>}
            {link_content.is_loading === false && link_content.success === true &&
                <div>
                    { true && link_content && link_content.data && (
                        <div className="bg-troto-1 text-troto-4 lg:h-screen lg:overflow-hidden">
                            <Header />

                            {link_content.type === "contenu" && (<div className="lg:grid lg:grid-cols-3 pt-16">
                                <div className="col-span-1 lg:mb-20 lg:h-screen lg:overflow-scroll lg:pb-20">
                                    <BackgroundImg content={link_content} />
                                    <Description content={link_content} />
                                    <AppStores />
                                </div>
                                <div className="col-span-2">
                                    <Suggestions content={link_content} />
                                </div>
                            </div>)}

                            {link_content.type === "dossier" && (<div className="lg:grid lg:grid-cols-3 pt-16">
                                <div className="col-span-1  overflow-scroll lg:mb-20">
                                <BackgroundImg content={link_content} />
                                <Description content={link_content} />
                                <AppStores />
                                </div>
                                <div className="col-span-2">
                                    <DossierContentList content={link_content} />
                                </div>
                                {/* <Suggestions content={link_content} /> */}
                            </div>)}

                            {link_content.type === "utilisateur" && (<div className="lg:grid lg:grid-cols-3 pt-16">
                                <div className="col-span-1  overflow-scroll lg:mb-20">
                                    <BackgroundImg content={link_content} />
                                    <Description content={link_content} />
                                    <AppStores />
                                </div>
                                <div className="col-span-2">
                                    <UserContents content={link_content}/>
                                </div>
                                {/* <Suggestions content={link_content} /> */}
                            </div>)}

                            {link_content.type === "artiste" && (<div className="lg:grid lg:grid-cols-3 pt-16">
                                <div className="col-span-1  overflow-scroll lg:mb-20">
                                    <BackgroundImg content={link_content} />
                                    <Description content={link_content} />
                                    <AppStores />
                                </div>
                                <div className="col-span-2">
                                    <UserContents content={link_content}/>
                                </div>
                                {/* <Suggestions content={link_content} /> */}
                            </div>)}


                            {/* <div>
                                <PlayerWidget />
                            </div> */}
                        </div>
                    )}
                </div>
            }
        </div>
    </>
}