import React, { useState } from "react";
import logo from "../../assets/img/logo_dark.png";

const DeleteUserData = () => {
  const [formData, setFormData] = useState({
    name: "",
    idType: "email",
    idValue: "",
    contactMethod: "email",
    message: "",
  });

  const [loading, setLoading] = useState(false); // New loading state
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null); // New error state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading state
    setError(null); // Reset any previous error messages

    // Prepare the data to be sent to the API
    const dataToSend = {
      name: formData.name,
      idType: formData.idType,
      idValue: formData.idValue,
      contactMethod: formData.contactMethod,
      message: formData.message,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/request-delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "An error occurred");
      }

      setSubmitted(true);
    } catch (err) {
      setError(err.message); // Display the error message on the UI
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-troto-dark-1 text-troto-dark-1">
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md">
        <img src={logo} alt="Troto Logo" className="w-32 mx-auto mb-4" />
        <h1 className="text-2xl font-bold text-center mb-6">Request Data Removal</h1>

        {submitted ? (
          <div className="text-center">
            <h2 className="text-lg font-semibold mb-4">Your request has been submitted</h2>
            <p>We will process your data removal request within 90 days. You will be notified via {formData.contactMethod === 'email' ? 'email' : 'SMS'}.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            {error && (
              <div className="text-red-600 text-center mb-4">
                {error} {/* Display error messages */}
              </div>
            )}

            <div>
              <label className="block font-medium">Full Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-troto-5"
              />
            </div>

            <div>
              <label className="block font-medium">ID Type</label>
              <select
                name="idType"
                value={formData.idType}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-troto-5"
              >
                <option value="email">Email Address</option>
                <option value="phone">Phone Number</option>
                <option value="google_email">Google Email</option>
                <option value="facebook_username">Facebook Username</option>
              </select>
            </div>

            <div>
              <label className="block font-medium">Enter ID</label>
              <input
                type={formData.idType === "phone" ? "tel" : "text"}
                name="idValue"
                value={formData.idValue}
                onChange={handleChange}
                required
                placeholder={`Enter your ${formData.idType.replace("_", " ")}`}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-troto-5"
              />
            </div>

            <div>
              <label className="block font-medium">Preferred Contact Method</label>
              <div className="flex space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="contactMethod"
                    value="email"
                    checked={formData.contactMethod === "email"}
                    onChange={handleChange}
                    className="text-troto-5 focus:ring-troto-5"
                  />
                  <span className="ml-2">Email</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="contactMethod"
                    value="sms"
                    checked={formData.contactMethod === "sms"}
                    onChange={handleChange}
                    className="text-troto-5 focus:ring-troto-5"
                  />
                  <span className="ml-2">SMS</span>
                </label>
              </div>
            </div>

            <div>
              <label className="block font-medium">Message (Optional)</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-troto-5"
                rows="4"
                placeholder="Additional details about your request..."
              />
            </div>

            <button
              type="submit"
              className={`w-full px-4 py-2 bg-troto-dark-1 text-white font-semibold rounded-md hover:bg-opacity-90 transition duration-300 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit Request"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default DeleteUserData;