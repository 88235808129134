import { FolderIcon, FolderOpenIcon, PlayIcon } from "@heroicons/react/16/solid";
import { _formatDate } from "../../../../utils/time_displayer";
import dossier_default_image from '../../../../assets/img/default.artwork.dossier.png';

export default function SearchDossierListElt(props) {

    const album = props.data;
    console.log(props.data);

    return <div className="bg-troto-1 border border-troto-2/80 rounded-md p-2 m-1 mb-2">
        <div className="pb-1 text-sm text-troto-5">
            <time dateTime={album.dateEnregistrement}>{album.dateEnregistrement ? _formatDate(album.dateEnregistrement) : ""}</time>
        </div> 

        <div className="flex gap-x-4">
            <div>
                <div className="w-[120px] h-[120px] overflow-hidden relative lg:rounded-lg ">
                    <img className="absolute inset-0 object-cover h-full w-full" src={album.artwork} onError={(e) => e.target.src = dossier_default_image} alt="" />
                </div>
            </div>
            <div className="flex-auto">
                <div className="flex items-baseline justify-between gap-x-4">
                    <div className="text-xl leading-6 font-bold text-troto-5">{album.titre}</div>
                </div>
                <div className="mt-1 my-1 line-clamp-2 text-sm leading-6 text-troto-4">
                    {album.description ? album.description : "Aucune description"}
                </div>

                <div className="my-1">
                    <div className="text-sm">{album.genre}</div>
                </div>

                <div className="mt-1 mb-2">
                    <span className="text-troto-rose-1"> <FolderOpenIcon className="h-5 inline relative top-[-2px]" /> Open </span>
                </div>
            </div>
        </div>
    </div>
}