import { ParallaxProvider } from 'react-scroll-parallax';
import MainRouter from './MainRouter';
import './output.css';

function App() {
  return (
    <ParallaxProvider>
      <div className="App min-h-screen min-w-screen bg-troto-dark-1 text-troto-5 font-raleway">
        <MainRouter />
      </div>
    </ParallaxProvider>
  );
}

export default App;
